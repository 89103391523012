<template>
  <div class="bg-white p-3 shadow d-flex flex-wrap h-100">
    <div class="mx-auto col-12 p-0">
      <div class="p-0 col-12 m-0 mb-2 d-flex">
        <div class="my-auto p-0 mr-2">
          <h4 class="card-title my-auto">{{ title }}</h4>
        </div>
        <div class="ml-auto d-flex my-auto">
          <b-input-group class="my-auto">
            <b-input-group-prepend>
              <span
                class="input-group-text p-0"
                style="
                  width: 44.22px;
                  height: 44.22px;
                  border: 1px solid #ced4da;
                  border-right: 0;
                "
              >
                <i
                  class="fa fa-search m-auto"
                  style="color: #8f9198"
                  aria-hidden="true"
                ></i
              ></span>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Rechercher"
              style="
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
              "
              class="pl-0 border-left-0"
              v-model="value"
            ></b-form-input>
          </b-input-group>
          <CustomAddButton
            v-if="canAdd"
            class="ml-1 my-auto"
            style="
              padding: 0.85rem !important;
              background-color: #ffb800;
              border-color: #ffb800;
              padding: 0.85rem !important;
            "
            @click="$emit('click')"
          />
        </div>
      </div>

      <slot name="table"></slot>
    </div>
    <div class="ml-auto mt-auto">
      <slot name="pagination"></slot>
    </div>
  </div>
</template>
<script>
import CustomAddButton from "@/components/custom/CustomAddButton.vue";

export default {
  components: {
    CustomAddButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: "",
    };
  },

  watch: {
    value() {
      window.console.log(this.value);
      this.$emit("change", this.value);
    },
  },
};
</script>
<style>
#targeted-table .b-table-empty-row {
  background-color: white !important;
}
#targeted-table thead {
  background-color: #f5f6fa !important;
  background: #f5f6fa !important;
  color: #131523 !important;
}
</style>
